import { Splide } from '@splidejs/splide';

class PromoBar extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    new Splide(this, {
      type: 'loop',
      height: '100%',
      width: '100%',
      perPage: 1,
      pagination: false,
      arrows: true,
      autoplay: true,
      interval: 3000,
      arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z'
    }).mount();
  }
}

customElements.define('promo-bar', PromoBar);
